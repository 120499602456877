@import "../../theme/variables.scss";

.createNoSceneTitle {
  white-space: pre-line;
  color: #{getColor("primary", "base")} !important;
  margin: 20px 0px 20px 0px !important;
}

.sceneRow {
  background: transparent !important;
  margin-right: -14px;
}

.noSceneRow {
  width: 430px !important;
  $height1: getSpace("xxl");
  $height2: getSpace("xs");
  height: $height1 + $height2 !important;
  background: rgba(0, 210, 166, 0.08) !important;
  border-radius: getSpace("xxs");
  cursor: pointer;
  display: flex;
}

.addmark {
  width: 60px !important;
  height: 60px !important;
  margin: getSpace("xxs") !important;
  padding: getSpace("xxs") !important;
  text-align: center !important;
  background: rgba(0, 210, 166, 0.08) !important;
  backdrop-filter: blur(12px) !important;
  font-size: getSpace("m");
}

.addIcon {
  color: #{getColor("primary", "base")} !important;
  height: getSpace("m") !important;
  width: getSpace("m") !important;
}

.firstSceneButtonCol {
  $marginLeft: getSpace("xs");
}

.firstSceneButton {
  margin-top: getSpace("s") !important;
}

.createFirstSceneButton {
  border-color: transparent !important;
  --box-shadow: none !important;
  --ripple-color: transparent !important;
}

.scrollingSceneList {
  display: flex;
  scroll-behavior: auto;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollingSceneList::-webkit-scrollbar {
  display: none;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .noSceneRow {
    width: 430px !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .noSceneRow {
    width: 430px !important;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Portrait)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .addmark {
    width: 32px !important;
    height: 32px !important;
    margin: getSpace("xs") !important;
    border-radius: 50% !important;
    background: #{getColor("glass", "whitethin")} !important;
  }
  .addmarkPosition {
    position: absolute !important;
    top: 0px;
    left: 2px;
    height: auto;
    width: auto;
  }

  .createNoSceneTitle {
    color: #{getColor("whiteColor", "a96")} !important;
    margin: unset !important;
  }
  .noSceneRow {
    background: #{getColor("glass", "whitethin")} !important;
    width: 272px !important;
    flex-direction: column;
    height: 120px !important;
  }
  .addIcon {
    color: #{getColor("whiteColor", "a96")} !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .addmark {
    width: 32px !important;
    height: 32px !important;
    margin: getSpace("xs") !important;
    border-radius: 50% !important;
    background: #{getColor("glass", "whitethin")} !important;
  }
  .addmarkPosition {
    position: absolute !important;
    top: 0px;
    left: 2px;
    height: auto;
    width: auto;
  }

  .createNoSceneTitle {
    color: #{getColor("whiteColor", "a96")} !important;
    margin: unset !important;
  }
  .noSceneRow {
    background: #{getColor("glass", "whitethin")} !important;
    width: 272px !important;
    flex-direction: column;
    height: 120px !important;
  }
  .addIcon {
    color: #{getColor("whiteColor", "a96")} !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 321px) and (max-width: 480px) {
  .addmark {
    width: 32px !important;
    height: 32px !important;
    margin: getSpace("xs") !important;
    border-radius: 50% !important;
    background: #{getColor("glass", "whitethin")} !important;
    position: relative;
  }
  .addmarkPosition {
    position: absolute !important;
    top: 0px;
    left: 2px;
    height: auto;
    width: auto;
  }

  .createNoSceneTitle {
    color: #{getColor("whiteColor", "a96")} !important;
    margin: unset !important;
  }
  .noSceneRow {
    background: #{getColor("glass", "whitethin")} !important;
    width: 272px !important;
    flex-direction: column;
    height: 120px !important;
  }
  .addIcon {
    color: #{getColor("whiteColor", "a96")} !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .addmark {
    width: 32px !important;
    height: 32px !important;
    margin: getSpace("xs") !important;
    border-radius: 50% !important;
    background: #{getColor("glass", "whitethin")} !important;
  }
  .addmarkPosition {
    position: absolute !important;
    top: 0px;
    left: 2px;
    height: auto;
    width: auto;
  }

  .createNoSceneTitle {
    color: #{getColor("whiteColor", "a96")} !important;
    margin: unset !important;
  }
  .noSceneRow {
    background: #{getColor("glass", "whitethin")} !important;
    width: 272px !important;
    flex-direction: column;
    height: 120px !important;
  }
  .addIcon {
    color: #{getColor("whiteColor", "a96")} !important;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (max-width: 320px) {
  .addmark {
    width: 32px !important;
    height: 32px !important;
    margin: getSpace("xs") !important;
    border-radius: 50% !important;
    background: #{getColor("glass", "whitethin")} !important;
  }
  .addmarkPosition {
    position: absolute !important;
    top: 0px;
    left: 2px;
    height: auto;
    width: auto;
  }

  .createNoSceneTitle {
    color: #{getColor("whiteColor", "a96")} !important;
    margin: unset !important;
  }
  .noSceneRow {
    background: #{getColor("glass", "whitethin")} !important;
    width: 250px !important;
    flex-direction: column;
    height: 120px !important;
  }
  .addIcon {
    color: #{getColor("whiteColor", "a96")} !important;
  }
}