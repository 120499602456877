@import "../../theme/variables.scss";

.accessoryListColorAnimationContainer {
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
  padding: 0px !important;
}

#accessoryListItemAnimation .accessoryListItemAnimationEndAnimationText {
  flex: auto;
  --background: #{getColor("elevation", "a03")} !important;
  --border: 0px;
  --background-hover: #{getColor("elevation", "a03")} !important;
}

#accessoryListItemAnimation .accessoryListItemAnimationEndAnimationButton {
  --background: #{getColor("elevation", "a03")} !important;
  --border: 0px;
  --color: #{getColor("whiteColor", "a96")} !important;
  --background-hover: #{getColor("elevation", "a03")} !important;
  --padding-start: 0px;
  --padding-end: 0px;
  margin-top: getSpace('s');
  margin-right: getSpace('s');
  margin-bottom: getSpace('s');
}

#accessoryListItemAnimation .endAnimationIcon {
  height: getSpace('m');
  width: getSpace('m');
}

#accessoryListItemAnimation .accessoryListItemAnimationContainer {
  --background: #{getColor("elevation", "a03")} !important;
  --border-radius: getSpace("xxs");
  --border-color: #{getColor("elevation", "a03")} !important;
  max-width: 800px;
  flex: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: right;
  align-content: flex-end;
  flex-wrap: nowrap;
  align-self: flex-end;
  border-radius: getSpace("xxs") !important;
}

#accessoryListItemAnimation .animationTextHeading {
  display: none;
}

#accessoryListItemAnimation .animationType {
  color: #{getColor("whiteColor", "a96")} !important;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .accessoryListColorAnimationContainer {
    justify-content: flex-start;
  }
  #accessoryListItemAnimation .accessoryListItemAnimationContainer {
    height: 56px !important;
    margin-right: getSpace("xxs") !important;
  }
  #accessoryListItemAnimation .accessoryListItemAnimationEndAnimationText {
    height: 56px !important;
    text-align: start;
  }

  #accessoryListItemAnimation .animationText {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: getSpace("xxs");
    margin-bottom: getSpace("xxs");
    margin-left: getSpace("xs");
  }
  #accessoryListItemAnimation .animationTextHeading {
    display: block !important;
    color: #{getColor("whiteColor", "a64")} !important;
  }
}