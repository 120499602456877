@import "../../../theme/variables.scss";


#createEditGroup .groupHeading {
  margin-top: getSpace("m") !important;
}

#createEditGroup .accessoryItemList {
  margin-left: -5% !important;
  margin-right: -2% !important;
  background: #{getColor("elevation", "a02")} !important;
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  #createEditGroup {
    overflow-y: auto !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 413px
*/

@media (min-width: 320px) and (max-width: 413px) and (orientation: landscape) {
  #createEditGroup {
    overflow-y: auto !important;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 414px) and (max-width: 767px) and (orientation: landscape) {
  #createEditGroup {
    overflow-y: auto !important;
  }
}