@import "../../theme/variables.scss";

.toolbar {
  color: #{getColor("glass", "medium")};
  --background: var(--wallHeaderColor, #{getColor("glass", "medium")});
  backdrop-filter: blur(250px);
  --min-height: 68px !important;
  text-align: center !important;
  --opacity: var(--colorOpacity, initial);
}

.arrows {
  color: #{getColor("whiteColor", "a96")} !important;
  margin-left: getSpace("s");
  margin-right: getSpace("s");
}

.toolbarButtons {
  --background-hover: #ffffff00 !important;
  color: #{getColor("whiteColor", "a96")} !important;
  --background: #{getColor("whiteColor", "a96")} !important;
  height: auto !important;
  margin-left: getSpace("s");
  margin-right: getSpace("s");
  --opacity: 1;
}

.toolbarLinks {
  color: #{getColor("primary", "base")} !important;
  margin-left: getSpace("s");
  margin-right: getSpace("s");
  --opacity: 1;
}

.ionTitle {
  text-align: center !important;
  --opacity: 1;
}

.ionSegment {
  --color: #{getColor("whiteColor", "a96")} !important;
}

.toolbarTitle {
  color: #{getColor("whiteColor", "a96")} !important;
  white-space: pre-wrap !important;
  --opacity: 1;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .start {
    margin-left: -3% !important;
  }
  .end {
    margin-right: -3% !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .start {
    margin-left: -5% !important;
  }
  .end {
    margin-right: -5% !important;
  }
}