@import "../../theme/variables.scss";

#colorWheel {
  position: relative;
  margin: 15%;
  border-radius: 50%;
  border: 22px solid #{getColor("elevation", "a00")} !important;
}

.colorWheelCss {
  position: relative;
  z-index: 1;
}

#colorWheel .colorCanvas {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  position: relative;
  z-index: 2;
}

#colorWheel .colorSelectionWheel {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border: 1px solid #f5f5f5;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 2vh rgba(0, 0, 0, 0.1), inset 0 0 2vh rgba(0, 0, 0, 0.1);

  /* default */
  top: 50%;
  left: 50%;
  z-index: 3;
}

#selectedColor {
  margin-top: 0em;
  width: 100%;
  text-align: center;
}

#selectedColor::before {
  content: "";
}

#selectedColor .selectedColorVal {
  /* display: inline-block; */
  display: none !important;
  width: 40%;
  height: 30px;
  margin-top: 10%;
}

#colorBrightness .colorBrightnessRange {
  width: 90%;
  margin-top: 0%;
  margin-left: 5%;
}

#colorBrightness .colorBrightnessRangeLabel {
  margin-top: -5%;
  width: 85px;
  margin-left: 5%;
  margin-bottom: 7%;
}

#colorBrightness .brightnessLabelColor {
  color: #{getColor("whiteColor", "a96")} !important;
}

#colorBrightness .colorBrightnessRangeLabelPercent {
  margin-top: -5%;
  margin-left: 65%;
}

.colorBrightnessDiv {
  margin-bottom: 120px;
}

#colorBrightness .numberCircle {
  display: flex;
  align-items: center;
  align-content: center;
  border-radius: 8%;
  padding: 25px !important;
  width: 66px;
  height: 64px;
  background: #{getColor("elevation", "a01")} !important;
  border: 1px solid #{getColor("utility", "outline")} !important;
  margin-left: 20px;
  margin-bottom: 25px;
}

#colorBrightness .imageContainer {
  width: 64px;
  height: 64px;
  border-radius: getSpace("xxs") !important;
  background: #{getColor("elevation", "a01")};
  border: 1px solid #{getColor("utility", "outline")} !important;
  box-sizing: border-box;
  margin-left: 20px;
  margin-bottom: 25px;
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = max 320px
*/

@media (max-width: 320px) {
  #colorBrightness .colorBrightnessRange {
    margin-bottom: 200px;
  }
}
