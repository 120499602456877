@import "../../theme/variables.scss";

#wallView .wallViewRoot {
  display: flex;
  flex-direction: column;
  scroll-behavior: auto;
  overflow-y: auto !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#wallView .wallViewContent {
  display: flex;
  flex-direction: column;
}

#wallView .wallHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#wallView .wallHeaderTitleSubtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-right: getSpace("xxs");
}

#wallView .hide {
  display: none;
}

/* #wallView .wallViewImage {
}
#wallView .wallViewScene {
}
#wallView .wallViewAccessory {
}
 */
#wallView .grid {
  margin: 0px;
}

#wallView .wallHeaderDiv {
  backdrop-filter: blur(250px);
  margin: 0px !important;
}

#wallView .headerImageRow {
  align-items: center;
  justify-content: center;
}

#wallView .headerTextRow {
  margin-top: getSpace("l") !important;
  align-items: center !important;
  flex-direction: row !important;
}

#wallView .sceneList {
  margin-top: 36px;
}

#wallView .accessoryTypography {
  margin-top: getSpace("xxxs");
}

#wallView .headerButtonCol {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
}

.wallSwitch {
  --ion-item-background: #{getColor("glass", "whitethin")};
  --border-color: #{getColor("glass", "whitethin")};
  margin-left: auto;
  border-radius: getSpace("xxs") !important;
  --border-radius: getSpace("xxs") !important;
  background: #{getColor("glass", "whitethin")};
  backdrop-filter: blur(250px);
}

#wallView .wallSwitchDiv {
  z-index: 300;
}

#wallView .wallSwitchTypography {
  margin-right: 10px;
  min-width: 100%;
}

#wallView .arrowBack {
  color: #{getColor("whiteColor", "a96")} !important;
  margin-top: getSpace("l");
  margin-bottom: getSpace("s");
  height: getSpace("m");
}

#wallView .wallAccessoryTitle {
  margin-top: getSpace("m");
  margin-bottom: getSpace("xs");
}

#wallView .offlineMessageDiv {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: getSpace("m") !important;
}

#wallView .offlineMessageIconDiv {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #{getColor("glass", "criticalthin")} !important;
  text-align: center;
  padding: 12px;
}
#wallView .offlineMessageIcon {
  width: 24px;
  height: 24px;
  color: #{getColor("critical", "base")} !important;
}

#wallView .offlineShortMessage {
  margin-top: getSpace("xxs");
}

#wallView .offlineMessage {
  margin-top: getSpace("m");
  margin-bottom: getSpace("m");
  margin-left: getSpace("xxs");
  text-align: center;
}

#wallView .offlineMessageButton {
  cursor: pointer;
  color: getColor("primary", "base");
  @include font("button");
  --box-shadow: none;
  padding: 0px !important;
  text-decoration: underline;
}

/*
  ##Device = Desktops
  ##Screen = 1280px to higher resolution desktops
*/

@media (min-width: 992px) {
  #wallView .wallViewRoot {
    height: 100%;
  }

  #wallView .wallHeaderDiv {
    background: #{getColor("glass", "medium")};
    padding-left: 142px !important;
    padding-right: 142px !important;
    width: 100%;
    position: relative;
  }

  #wallView .wallViewContentData {
    padding-left: 142px !important;
    padding-right: 142px !important;
  }

  .headerTextRowDisplay {
    display: flex;
  }

  .wallSwitchRightMargin {
    margin-right: 1.5% !important;
  }

  .wallAccessoryTitleLeftMargin {
    margin-left: getSpace("xxs");
  }

  #wallView .wallImage {
    width: 160px;
    height: 90px;
    margin-top: getSpace("s") !important;
    margin-right: getSpace("s") !important;
    margin-bottom: getSpace("m") !important;
  }
  #wallView .wallHeaderTitleSubtitle {
    flex: auto;
  }
  #wallView .wallSpinner {
    align-self: center;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1023px
*/
@media (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
  #wallView .wallHeaderDiv {
    background: #{getColor("glass", "medium")};
    padding-left: getSpace("s") !important;
    padding-right: getSpace("s") !important;
    width: 100%;
    position: relative;
  }

  #wallView .wallViewContentData {
    padding-left: getSpace("s") !important;
    padding-right: getSpace("s") !important;
  }

  .headerTextRowDisplay {
    display: flex;
  }

  .wallSwitchRightMargin {
    margin-right: 1.5% !important;
  }

  .wallAccessoryTitleLeftMargin {
    margin-left: getSpace("xxs");
  }

  #wallView .wallImage {
    width: 160px;
    height: 90px;
    margin-top: getSpace("s") !important;
    margin-right: getSpace("s") !important;
    margin-bottom: getSpace("m") !important;
  }
  #wallView .wallHeaderTitleSubtitle {
    flex: auto;
  }

  #wallView .wallSpinner {
    align-self: center;
    height: 25vh;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
  #wallView .wallHeaderDiv {
    background: #{getColor("glass", "medium")};
    padding-left: getSpace("s") !important;
    padding-right: getSpace("s") !important;
    width: 100%;
    position: relative;
  }

  #wallView .wallViewContentData {
    padding-left: getSpace("s") !important;
    padding-right: getSpace("s") !important;
  }

  .headerTextRowDisplay {
    display: flex;
  }

  .wallSwitchRightMargin {
    margin-right: 1.5% !important;
  }

  .wallAccessoryTitleLeftMargin {
    margin-left: getSpace("xxs");
  }

  #wallView .wallImage {
    width: 160px;
    height: 90px;
    margin-top: getSpace("s") !important;
    margin-right: getSpace("s") !important;
    margin-bottom: getSpace("m") !important;
  }
  #wallView .wallHeaderTitleSubtitle {
    flex: auto;
  }
  #wallView .wallSpinner {
    align-self: center;
    height: 50vh;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 414px to 736px
*/

@media (min-width: 414px) and (max-width: 736px) and (orientation: portrait) {
  .headerTextRowDisplay {
    display: inherit;
  }

  .ionToggleSwitch {
    margin: auto;
  }

  .wallSwitchRightMargin {
    margin-right: getSpace("s") !important;
  }
  #wallView .wallHeaderTitleSubtitle {
    word-break: break-all;
    margin-right: 0px !important;
  }

  .wallAccessoryTitleLeftMargin {
    margin-left: getSpace("xs");
  }

  .wallNameAccessoryTypography {
    margin-left: 3%;
  }

  #wallView .wallImage {
    width: 160px;
    height: 81px;
    margin-bottom: getSpace("m") !important;
    flex: 1 !important;
  }

  #wallView .wallHeader {
    margin-left: getSpace("s") !important;
    margin-right: getSpace("s") !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  #wallView .wallHeaderImageRow {
    display: flex;
    align-items: top;
    justify-content: space-between;
  }

  #wallView .wallHeaderTitle {
    margin-top: 0px !important;
    margin-right: 20px !important;
  }

  #wallView .wallSwitchDiv {
    width: 165px !important;
    height: 44px !important;
    margin-top: getSpace("m") !important;
    margin-bottom: getSpace("m") !important;
  }

  #wallView .sceneList {
    margin-top: getSpace("m") !important;
    margin-left: getSpace("s") !important;
  }

  #wallView .wallViewAccessory {
    margin-top: getSpace("m") !important;
    margin-left: getSpace("s") !important;
    margin-right: getSpace("s") !important;
  }

  #wallView .sceneListRow {
    padding-top: 0px !important;
  }

  #wallView .wallHeaderDiv {
    position: relative;
    backdrop-filter: blur(250px);
    display: flex;
    z-index: 2;
  }

  #wallView .wallColorLayer {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    min-width: 100%;
    background: var(--wallHeaderColor, #{getColor("glass", "medium")});
    opacity: var(--colorOpacity, initial);
    z-index: 2;
  }

  #wallView .wallSpinner {
    align-self: center;
    height: 25vh;
  }
}

/*
  ##Device = Smartphones Mobiles (Landscape)
  ##Screen = B/w 414px to 746px
*/

@media (min-width: 414px) and (max-width: 736px) and (orientation: landscape) {
  #wallView .wallHeaderDiv {
    padding-left: getSpace("s") !important;
    padding-right: getSpace("s") !important;
    width: 100%;
    position: relative;
  }

  #wallView .wallViewContentData {
    padding-left: getSpace("s") !important;
    padding-right: getSpace("s") !important;
  }

  .headerTextRowDisplay {
    display: flex;
  }

  .wallSwitchRightMargin {
    margin-right: 1.5% !important;
  }

  .wallAccessoryTitleLeftMargin {
    margin-left: getSpace("xxs");
  }

  #wallView .wallImage {
    width: 160px;
    height: 90px;
    margin-top: getSpace("s") !important;
    margin-right: getSpace("s") !important;
    margin-bottom: getSpace("m") !important;
  }
  #wallView .wallHeaderTitleSubtitle {
    flex: auto;
  }

  #wallView .wallSpinner {
    align-self: center;
    height: 50vh;
  }
}

/*
  ##Device = Smartphones Mobiles (Landscape)
  ##Screen = B/w 375px to 667px
*/

@media (min-width: 375px) and (max-width: 667px) and (orientation: landscape) {
  #wallView .wallHeaderDiv {
    padding-left: getSpace("s") !important;
    padding-right: getSpace("s") !important;
    width: 100%;
    position: relative;
  }

  #wallView .wallViewContentData {
    padding-left: getSpace("s") !important;
    padding-right: getSpace("s") !important;
  }

  .headerTextRowDisplay {
    display: flex;
  }

  .wallSwitchRightMargin {
    margin-right: 1.5% !important;
  }

  .wallAccessoryTitleLeftMargin {
    margin-left: getSpace("xxs");
  }

  #wallView .wallImage {
    width: 160px;
    height: 90px;
    margin-top: getSpace("s") !important;
    margin-right: getSpace("s") !important;
    margin-bottom: getSpace("m") !important;
  }
  #wallView .wallHeaderTitleSubtitle {
    flex: auto;
    width: 50%;
  }
  #wallView .wallSpinner {
    align-self: center;
    height: 50vh;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 413px
*/

@media (min-width: 320px) and (max-width: 413px) and (orientation: portrait) {
  #wallView .wallViewContent {
    min-height: 400px;
    height: auto;
  }

  .headerTextRowDisplay {
    display: inherit;
  }

  .ionToggleSwitch {
    margin: auto;
  }

  .wallSwitchRightMargin {
    margin-right: getSpace("s") !important;
  }
  #wallView .wallHeaderTitleSubtitle {
    word-break: break-all;
    margin-right: 0px !important;
  }

  .wallAccessoryTitleLeftMargin {
    margin-left: getSpace("xs");
  }

  .wallNameAccessoryTypography {
    margin-left: 3%;
  }

  #wallView .wallImage {
    width: 160px;
    height: 81px;
    margin-bottom: getSpace("m") !important;
    flex: 1 !important;
  }

  #wallView .wallHeader {
    margin-left: getSpace("s") !important;
    margin-right: getSpace("s") !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 3;
    width: 100%;
  }

  #wallView .wallViewContentData {
    /* top: 176px; */
    position: relative;
  }

  #wallView .wallHeaderImageRow {
    display: flex;
    align-items: top;
    justify-content: space-between;
  }

  #wallView .wallHeaderTitle {
    margin-top: 0px !important;
    margin-right: 20px !important;
  }

  #wallView .wallSwitchDiv {
    width: 165px !important;
    height: 44px !important;
    margin-top: getSpace("m") !important;
    margin-bottom: getSpace("m") !important;
  }

  #wallView .sceneList {
    margin-top: getSpace("m") !important;
    margin-left: getSpace("s") !important;
  }

  #wallView .wallViewAccessory {
    margin-top: getSpace("m") !important;
    margin-left: getSpace("s") !important;
    margin-right: getSpace("s") !important;
  }

  #wallView .sceneListRow {
    padding-top: 0px !important;
  }

  #wallView .wallHeaderDiv {
    backdrop-filter: blur(250px);
    display: flex;
    z-index: 2;
    min-width: 100%;
    min-height: 146px;
    max-height: 176px;
  }

  #wallView .wallColorLayer {
    min-width: 100%;
    position: absolute;
    z-index: 2;
    /*     top: 0px;
    left: 0px;
    min-height: 146px;
    max-height: 176px;
    height: 100%; */
    box-sizing: border-box;
  }

  #wallView .wallOffColorLayer {
    background: var(--wallHeaderColor, #{getColor("glass", "medium")});
  }

  #wallView .wallOnColorLayer {
    background: var(--wallHeaderColor, #{getColor("glass", "medium")});
    opacity: var(--colorOpacity, initial);
  }

  #wallView .wallSpinner {
    align-self: center;
    position: relative;
    /* top: 185px; */
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 413px
*/

@media (min-width: 320px) and (max-width: 413px) and (orientation: landscape) {
  .headerTextRowDisplay {
    display: inherit;
  }

  .wallSwitchRightMargin {
    margin-right: getSpace("s") !important;
  }

  .wallAccessoryTitleLeftMargin {
    margin-left: getSpace("xs");
  }

  .wallNameAccessoryTypography {
    margin-left: 3%;
  }

  #wallView .wallImage {
    width: 160px;
    height: 81px;
    margin-right: 25% !important;
    margin-left: 25% !important;
    margin-top: getSpace("m") !important;
    margin-bottom: getSpace("m") !important;
    flex: 1 !important;
  }
  #wallView .sceneList {
    margin-top: getSpace("m") !important;
  }
  #wallView .sceneListRow {
    padding-top: 0px !important;
  }

  #wallView .wallSpinner {
    align-self: center;
    height: 50vh;
  }
}