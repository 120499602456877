@import "../../theme/variables.scss";

.colorPickerContainer {
  height: 100% !important;
  width: 100% !important;
}

#colorPicker .colorCircleButton {
  --background: none;
  border: none !important;
  outline: none !important;
  --box-shadow: none;
  height: 100%;
  width: 100%;
  --padding-bottom: 0px;
  --padding-top: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  padding: 0px;
  margin: 0px;
}

.colorModal .modal-wrapper {
  background: getColor("elevation", "a02");
  height: 450px;
  width: 320px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  border-radius: getSpace("xxs");
}

.colorModal .colorModalContent {
  /* display: flex; */
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100%;
}

ion-fab-button {
  --background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.14),
      rgba(255, 255, 255, 0.14)
    ),
    #0e1010;
  --color: getColor("elevation", "a03");
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .colorModal {
    .modal-wrapper {
      height: 570px !important;
      width: 415px !important;
      margin-top: 260px !important;
    }
  }
  .colorModalHorizontalBar {
    width: 64px;
    height: 4px;
    margin-top: 8px;
    align-self: center;
    border-radius: 40px;
    background: #{getColor("elevation", "a05")} !important;
  }

  #colorPicker .pickerText {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: getSpace("xxs");
    margin-bottom: getSpace("xxs");
    margin-left: getSpace("xs");
    align-items: flex-start !important;
  }

  #colorPicker .pickerTextHeading {
    display: block !important;
    color: #{getColor("whiteColor", "a64")} !important;
  }

  #colorPicker .pickerType {
    color: #{getColor("whiteColor", "a96")} !important;
  }

  #colorPicker .brightnessButton {
    flex: auto;
    --background: #{getColor("elevation", "a03")} !important;
    --border: 0px;
    --background-hover: #{getColor("elevation", "a03")} !important;
    --background-activated: #{getColor("elevation", "a03")} !important;
    border: none !important;
    outline: none !important;
    --box-shadow: none;
    height: 100%;
    width: 100%;
    --padding-bottom: 0px;
    --padding-top: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    padding: 0px;
    margin: 0px;
  }
  .brightnessModal .modal-wrapper {
    height: 340px !important;
    width: 415px !important;
    margin-top: 500px !important;
    background: getColor("elevation", "a02");
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    border-radius: getSpace("xxs");
  }

  #colorPicker .brightnessBox {
    background: #{getColor("glass", "medium")} !important;
    width: 81px !important;
    height: 28px !important;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-right: #{getSpace("xs")};
    border-radius: #{getSpace("xxs")};
    display: flex;
  }

  #colorPicker .sliderIcon {
    margin-left: getSpace("xxs");
    margin-right: unset !important;
    margin-top: getSpace("xxxs");
    margin-bottom: getSpace("xxxs");
    width: 20px !important;
    height: 20px !important;
    background: url("../../assets/images/wb_sunny-white.svg") no-repeat center;
    filter: brightness(0.6);
  }

  #colorPicker .brightnessValue {
    color: #{getColor("whiteColor", "a96")} !important;
    width: 41px;
    height: getSpace("m");
    margin-left: getSpace("xxxs");
    margin-right: getSpace("xxs");
    margin-top: getSpace("xxxxs");
    margin-bottom: getSpace("xxxxs");
    text-align: right;
    flex: auto;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = max 320px
*/

@media (max-width: 320px) {
  .brightnessModal .modal-wrapper {
    height: 410px !important;
  }
}
